import {
  configureStore,
  getDefaultMiddleware,
  ThunkAction,
  Action,
  combineReducers,
} from '@reduxjs/toolkit';
import appReducer from './slices/app';
import authReducer from './slices/auth';
import configReducer from './slices/config';
import historyReducer from './slices/history';
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  whitelist: ['config'],
};

const rootReducer = combineReducers({
  app: appReducer,
  auth: authReducer,
  config: configReducer,
  history: historyReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
