import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../store';
import { Designer, NullableString } from '@nobody/shared/src/types/app';
import { firestore } from '../../utils/firebase';
import _ from 'lodash';

interface AuthState {
  isSignedIn: boolean;
  user: any | null;
  error: NullableString;
  isLoading: boolean;
  claims: any;
}

const initialState: AuthState = {
  isSignedIn: false,
  user: null,
  error: null,
  isLoading: false,
  claims: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    signIn: (state, action: PayloadAction<object>) => {
      state.user = action.payload;
      state.isSignedIn = true;
      state.error = null;
    },
    signOut: (state) => {
      state.isSignedIn = false;
      state.user = null;
      state.error = null;
      state.claims = null;
    },
    setError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    updateEmail: (state, action: PayloadAction<string>) => {
      state.user = { ...state.user, email: action.payload };
    },
    updatePhotoURL: (state, action: PayloadAction<string>) => {
      state.user = { ...state.user, photoURL: action.payload };
    },
    setClaims: (state, action: PayloadAction<any>) => {
      state.claims = action.payload;
    },
    setProfile: (
      state,
      action: PayloadAction<Designer | Partial<Designer>>
    ) => {
      state.user = { ...state.user, ...action.payload };
    },
    startLoading: (state) => {
      state.isLoading = true;
    },
    endLoading: (state) => {
      state.isLoading = false;
    },
  },
});

export const {
  signIn,
  signOut,
  setError,
  updateEmail,
  updatePhotoURL,
  setProfile,
  startLoading,
  endLoading,
  setClaims,
} = authSlice.actions;

export const fetchUser =
  (uid: string, emailVerified: boolean): AppThunk =>
  async (dispatch) => {
    try {
      const doc = await firestore.collection('users').doc(uid).get();
      const data = doc.data();
      if (!data) {
        throw Error;
      }
      dispatch(signIn({ ..._.omit(data, 'createdAt'), uid, emailVerified }));
    } catch (err) {
      dispatch(setError('ログインに失敗しました。再度お試しください。'));
      console.log(err);
    }
  };

export const updateProfile =
  (uid: string, profile: Partial<Designer>, callback?: () => any): AppThunk =>
  async (dispatch) => {
    dispatch(startLoading());
    try {
      await firestore.collection('users').doc(uid).update(profile);

      dispatch(setProfile(profile));
      dispatch(endLoading());
      if (typeof callback === 'function') {
        callback();
      }
    } catch (err) {
      dispatch(
        setError('プロフィール更新に失敗しました。再度お試しください。')
      );
      dispatch(endLoading());

      console.log(err);
    }
  };

export default authSlice.reducer;
