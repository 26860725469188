import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type Theme = 'dark' | 'light';

interface ConfigState {
  theme: Theme;
}

const initialState: ConfigState = {
  theme: 'dark',
};

export const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    setTheme: (state, action: PayloadAction<Theme>) => {
      state.theme = action.payload;
    },
  },
});

export const { setTheme } = configSlice.actions;

export default configSlice.reducer;
