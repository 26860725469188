import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';

const persistor = persistStore(store);

if (process.env.NODE_ENV === 'production') {
  const noop = () => {};

  console.log = noop;
}

function importBuildTarget() {
  if (process.env.REACT_APP_TARGET === 'admin') {
    return import('./components/AdminApp');
  } else if (process.env.REACT_APP_TARGET === 'designer') {
    return import('./components/DesignerApp');
  } else {
    return Promise.reject(
      new Error('No such build target: ' + process.env.REACT_APP_TARGET)
    );
  }
}

importBuildTarget().then(({ default: Target }) =>
  ReactDOM.render(
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Target />
      </PersistGate>
    </Provider>,
    document.getElementById('root')
  )
);
