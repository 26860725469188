import firebaseClient from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

firebaseClient.initializeApp(firebaseConfig);

const REGION = 'asia-northeast1';

export default firebaseClient;
export const firebaseAuth = firebaseClient.auth();
export const firestore = firebaseClient.firestore();
export const firebaseFunctions = firebaseClient.app().functions(REGION);
export const firebaseStorage = firebaseClient.storage();

export type DocumentSnapshot = firebaseClient.firestore.DocumentSnapshot;
export type DocumentData = firebaseClient.firestore.DocumentData;
export type DocumentReference = firebaseClient.firestore.DocumentReference;
export type Query = firebaseClient.firestore.Query;
export type QuerySnapshot = firebaseClient.firestore.QuerySnapshot;
export type QueryDocumentSnapshot =
  firebaseClient.firestore.QueryDocumentSnapshot<DocumentData>[];
export type Timestamp = firebaseClient.firestore.Timestamp;
