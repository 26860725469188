import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface HistoryState {
  pathnames: string[];
}

const initialState: HistoryState = {
  pathnames: [],
};

export const historySlice = createSlice({
  name: 'history',
  initialState,
  reducers: {
    setPath: (state, action: PayloadAction<string>) => {
      state.pathnames = [...state.pathnames, action.payload];
    },
    popPath: (state) => {
      state.pathnames = state.pathnames.slice(0, state.pathnames.length - 1);
    },
  },
});

export const { setPath, popPath } = historySlice.actions;

export default historySlice.reducer;
